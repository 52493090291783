.chart-token-selector {
    padding-top: 0!important;
    padding-bottom: 0!important;
}

.chart-token-selector--current {
    font-size: 2.15rem;
    font-weight: 700;
    margin-right: 1rem;
}

.chart-token-menu-items.menu-items {
    width: 15.8rem;
    top: 6rem;
    right: unset;
    left: 0;
}
