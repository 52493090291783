@tailwind utilities;
@tailwind base;
@tailwind components;

.Tooltip {
  position: relative;
}

.Tooltip-popup {
  @apply bg-bg-color-5 border dark:bg-gray-600 dark:border-gray-900;
  font-size: 1.2rem;
  line-height: 1.6rem;
  position: absolute;
  border-radius: 1rem;
  padding: 1.05rem;
  letter-spacing: 0.4px;
  min-width: 25rem;
  text-align: left;
  z-index: 1000;
}

.tooltip-price .Tooltip-popup {
  min-width: 13rem !important;
}

.Tooltip.nowrap .Tooltip-popup {
  min-width: auto;
  white-space: nowrap;
}

.Tooltip-popup :first-child {
  margin-top: 0;
}

.Tooltip-popup :last-child {
  margin-bottom: 0;
}

.Tooltip-popup.left-bottom {
  left: 0;
  transform: translateY(0.8rem);
  top: 100%;
}

.Tooltip-popup.right-bottom {
  right: 0;
  transform: translateY(0.8rem);
  top: 100%;
}

.Tooltip-popup.right-top {
  right: 0;
  transform: translateY(-0.8rem);
  bottom: 100%;
}
.Tooltip-popup.right {
  left: 3rem;
  transform: translateY(50%);
  bottom: 100%;
}

.Tooltip-popup.left-top {
  left: 0;
  transform: translateY(-0.8rem);
  bottom: 100%;
}

.Tooltip-popup.center-bottom {
  left: 50%;
  transform: translateY(0.8rem) translateX(-50%);
  top: 100%;
}

.Tooltip-popup.center-top {
  left: 50%;
  transform: translateY(-0.8rem) translateX(-50%);
  bottom: 100%;
}

.Tooltip-handle {
  @apply dark:text-white;
  cursor: pointer;
  position: relative;
  display: inline-flex;
}

.Tooltip-handle.plain:after {
  border-bottom: none;
}

.Tooltip.no-underline .Tooltip-handle::after {
  content: unset;
}

.Tooltip-divider {
  height: 1px;
  background-color: rgba(255, 255, 255, 0.45);
  margin: 0.5rem 0;
}

.Tooltip-row {
  margin: 0 0 0.5rem 0;
}

.Tooltip-arrow,
.Tooltip-arrow::before {
  position: absolute;
  width: 8px;
  height: 8px;
  background: inherit;
  left: 50%;
  transform: translate(-100%);
}

.Tooltip-arrow {
  visibility: hidden;
}

.Tooltip-arrow::before {
  visibility: visible;
  content: "";
  transform: rotate(45deg);
}

.Tooltip-popup[data-popper-placement^="top"] > .Tooltip-arrow {
  bottom: -4px;
}

.Tooltip-popup[data-popper-placement^="bottom"] > .Tooltip-arrow {
  top: -4px;
}

.Tooltip-popup[data-popper-placement^="left"] > .Tooltip-arrow {
  right: -4px;
}

.Tooltip-popup[data-popper-placement^="right"] > .Tooltip-arrow {
  left: -4px;
}

@media (max-width: 700px) {
  .Tooltip-popup {
    min-width: 20rem;
  }
}
