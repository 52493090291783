.buy-input .InputSection-static-input {
  font-size: 2.325rem;
}

.buy-input.Exchange-swap-section {
  min-height: 101px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.buy-input .TokenSelector-box {
  display: flex;
  align-items: center;
  border-radius: 0.4rem;
  min-width: 4.65rem;
  font-size: 1.9rem;
  line-height: 2.5rem;
  justify-content: flex-end;
}
.buy-input .TokenSelector-box:hover {
  color: #7885ff;
}

.buy-input .Exchange-swap-input-container {
  position: relative;
  overflow: hidden;
}

.buy-input .Exchange-swap-max {
  position: absolute;
  right: 1.25rem;
  top: 2px;
  padding: 0.465rem 0.8rem;
  z-index: 1;
  cursor: pointer;
  font-size: 1.2rem;
  @apply bg-bg-color-4 text-white rounded-2xl;
}

.buy-input .Exchange-swap-section-bottom .TokenSelector-box {
  font-size: 2.1rem;
}

.buy-input .TokenSelector-caret {
  margin: 0;
  margin-right: -0.5rem;
}

.buy-input .TokenSelector-box-symbol {
  margin-left: 0.8rem;
}

.buy-input .PositionEditor-token-symbol {
  font-size: 2.1rem;
  text-align: right;
}

.buy-input .PositionEditor-token-symbol img {
  display: none;
}

.buy-input .Exchange-swap-section-top {
  display: grid;
  grid-template-columns: auto auto;
  font-size: 1.4rem;
  padding-bottom: 1.25rem;
}
.buy-input .selected-token {
  /* padding-top: 0.5rem; */
  /* padding-bottom: 0.5rem; */
  display: flex;
  align-items: center;
}

.Exchange-swap-ball-container {
  position: relative;
  z-index: 1;
}

.Exchange-swap-ball-icon {
  text-align: center;
  display: block;
  transform: rotate(90deg);
  font-size: 2rem;
}

.Exchange-swap-ball {
  position: absolute;
  width: 3.565rem;
  height: 3.565rem;
  left: 50%;
  margin-left: -1.7825rem;
  top: -1.9375rem;
  border-radius: 3.1rem;
  cursor: pointer;
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 4px rgb(71 73 79 / 10%), 0 1px 2px rgb(71 73 79 / 25%);
  @apply bg-bg-color-4 text-white;
}
