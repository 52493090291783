@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;

.Modal {
  position: fixed;
  z-index: 900;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;
}

.Modal-scrollable .Modal-content .Modal-body {
  overscroll-behavior: smooth;
  max-height: calc(80vh - 5.5rem);
  overflow-y: auto;
  padding-right: 5px;
}

.Modal-backdrop {
  @apply bg-black bg-opacity-50;
  position: fixed;
  z-index: 10;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.Modal-content {
  @apply bg-white border-gray-300 rounded-[24px] z-20 relative max-w-full overflow-auto shadow-lg dark:bg-[#1A1B1F] dark:border-2 dark:border-[#3e476d];
  min-width: 36rem;
  max-height: 90vh;
}

.divider {
  @apply border-b border-gray-200 dark:border-gray-700;
  margin-bottom: 1.5rem;
}

.Modal.non-scrollable .Modal-content {
  overflow: visible;
}

.Modal-title-bar {
  @apply mb-0 mx-[2.3rem] my-[1.8rem] sm:mt-[1.8rem];
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.Modal-body {
  @apply mt-5 mx-[2.3rem] my-[1.8rem] sm:mt-[1.8rem];
}
.Modal-body::-webkit-scrollbar {
  width: 0.6rem;
}

.Modal-body::-webkit-scrollbar-track {
  background-color: #1c1c1c;
  border-radius: 155rem;
}

.Modal-body::-webkit-scrollbar-thumb {
  background-color: #949393;
  border-radius: 155rem;
}

.Modal-title {
  font-size: 18px;
  line-height: 24px;
  font-weight: 800;
}

.Modal-close-button {
  text-align: right;
}

.Modal-close-icon {
  opacity: 0.6;
  cursor: pointer;
  text-align: right;
  display: inline-block;
}

.Modal-close-icon:hover {
  opacity: 0.9;
}

.Modal-note {
  margin-bottom: 1.5rem;
  margin-top: 0.8rem;
}
