@tailwind base;
@tailwind components;
@tailwind utilities;

.Tab.block {
  display: grid;
  grid-auto-flow: column;
  font-size: 1.3rem;
  overflow: hidden;
  @apply bg-bg-color-2 text-txt-color-2 font-bold rounded-2xl dark:text-white;
}

.Tab-option-icon {
  @apply fill-bg-color-4 dark:fill-white h-10 w-10;
  opacity: 0.7;
}

.Tab.block .Tab-option {
  text-align: center;
  padding: 0.8rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  cursor: pointer;
}

.Tab.block .Tab-option.active {
  opacity: 1;
  pointer-events: none;
  @apply bg-bg-color-4 text-white;
}

.Tab.block .Tab-option.active .Tab-option-icon {
  @apply fill-white;
  opacity: 1;
}

.Tab.inline .Tab-option {
  cursor: pointer;
  display: inline-block;
  margin-right: 1.5rem;
}

.Tab.inline .Tab-option:hover {
  opacity: 0.8;
}

.Tab.inline .Tab-option.active {
  opacity: 1;
  pointer-events: none;
}

.Tab.inline-flex .Tab-option {
  cursor: pointer;
  display: inline-block;
  margin-right: 1.5rem;
}

.Tab.inline-flex .Tab-option:hover {
  opacity: 0.8;
}

.Tab.inline-flex .Tab-option.active {
  opacity: 1;
  pointer-events: none;
  @apply bg-gray-300 dark:text-gray-900;
}
