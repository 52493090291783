@tailwind utilities;
@tailwind base;
@tailwind components;
.asset-menu-items {
  font-size: 1.6rem;
  position: absolute;
  background: var(--dark-blue);
  outline: none;
  z-index: 100;
  overflow: hidden;
  border-radius: 1rem;
  @apply bg-bg-color-5 border border-bg-color-2 dark:bg-gray-600 dark:border-gray-900 shadow-lg;
}

.center-both {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dropdown-arrow {
  margin-left: 0.8rem;
  cursor: pointer;
  color: white;
  opacity: 0.8;
}
.dropdown-arrow:hover {
  opacity: 1;
}

.asset-item {
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  padding: .8rem 1rem;
}
.asset-item:hover {
  background: var(--dark-blue-hover);
}
.asset-item p {
  margin: 0;
  margin-left: 0.5rem;
}
