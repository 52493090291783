.react-dropdown-select-dropdown {
  @apply z-10 !w-[270px] block text-2xl !p-2 !rounded-2xl !overflow-x-hidden;
}

.date-range-custom {
  color: #000;
  margin: 0.5rem;
  text-align: center;
}

.date-range-custom .rdrMonth {
  width: 240px;
}

.date-range-custom .rdrCalendarWrapper {
  @apply w-full text-xl;
}

.date-range-custom .rdrNextPrevButton {
  @apply bg-none;
}

.recharts-wrapper {
  font-size: 1rem;
}

.recharts-legend-item {
  padding-bottom: 1px !important;
  margin-right: 5px !important;
}

.recharts-legend-item-text {
  display: inline-block;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
}
